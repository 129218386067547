<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>{{ $t('admin.categories') }}</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
            </path>
          </svg>
          <span>Kategorie hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="search-wrap">
        <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>

        <div v-if="categories.length == 0" class="alert alert-info">
          <p>Aktuell sind noch keine Kategorien vorhanden.</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Beschreibung</th>
                <th>Standort</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="category in categories" v-bind:key="category.id">
                <td width="20%">
                  {{ category.name }}
                </td>
                <td width="35%">
                  {{ category.description }}
                </td>
                <td width="20%">
                  <span v-if="category.location">{{ category.location.name }}</span>
                </td>
                <td width="15%">
                  <span v-if="category.status == 'hidden'" class="status-hidden">
                    <span class="status-icon"></span>
                    <span class="status-text">Ausgeblendet</span>
                  </span>
                  <span v-if="category.status == 'visible'" class="status-visible">
                    <span class="status-icon"></span>
                    <span class="status-text">Sichtbar</span>
                  </span>
                  <span v-if="category.status == 'deleted'" class="status-deleted">
                    <span class="status-icon"></span>
                    <span class="status-text">Gelöscht</span>
                  </span>
                </td>
                <td width="10%" class="actions">
                  <a @click="edit_category(category)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </a>
                  <!--<a @click="delete_category(category)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </a>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Kategorie hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="category.status" class="form-input">
            <option key="hidden" value="hidden">Ausgeblendet</option>
            <option key="visible" value="visible">Sichtbar</option>
            <option key="deleted" value="deleted">Gelöscht</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input v-model="category.name" class="form-input input-grey" type="text" name="name" id="name">
        </div>

        <div class="form-wrap">
          <label for="description">Beschreibung</label>
          <textarea v-model="category.description" id="description" name="description" rows="4"
            class="form-input"></textarea>
        </div>

        <div class="form-wrap select-wrap">
          <label for="location_id">Standort</label>
          <select v-model="category.location_id" class="form-input">
            <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_category" class="button button-red button-100">Kategorie erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_edit">
      <svg @click="modal_edit = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Kategorie bearbeiten</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="selected_category.status" class="form-input">
            <option key="hidden" value="hidden">Ausgeblendet</option>
            <option key="visible" value="visible">Sichtbar</option>
            <option key="deleted" value="deleted">Gelöscht</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input v-model="selected_category.name" class="form-input input-grey" type="text" name="name" id="name">
        </div>

        <div v-if="languages.length > 1" class="translation-wrap">
          <div v-for="lang in languages" v-bind:key="lang.id">
            <div v-if="lang.slug != 'de'" class="form-wrap">
              <label :for="'name_' + lang.id" class="form-label">Name ({{ lang.name }})</label>
              <input v-model="selected_category['name_i18n_' + lang.slug]" class="form-input input-grey" type="text"
                :name="'name_' + lang.id" :id="'name_' + lang.id">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="description">Beschreibung</label>
          <textarea v-model="selected_category.description" id="description" name="description" rows="4"
            class="form-input"></textarea>
        </div>

        <div v-if="languages.length > 1" class="translation-wrap">
          <div v-for="lang in languages" v-bind:key="lang.id">
            <div v-if="lang.slug != 'de'" class="form-wrap">
              <label :for="'description_' + lang.id" class="form-label">Beschreibung ({{ lang.name }})</label>
              <textarea v-model="selected_category['description_i18n_' + lang.slug]" :name="'description_' + lang.id"
                :id="'description_' + lang.id" rows="4" class="form-input"></textarea>
            </div>
          </div>
        </div>

        <div class="form-wrap select-wrap">
          <label for="location_id">Standort</label>
          <select v-model="selected_category.location_id" class="form-input">
            <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="update_category" class="button button-red button-100">Kategorie bearbeiten ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_categories',
  data() {
    return {
      loading: true,
      modal_add: false,
      modal_edit: false,
      locations: [],
      categories: [],
      category: {},
      selected_category: {},
      search_loading: false,
      search_phrase: "",
      searching: false,
      languages: [],
      user_id: null,
      user: {},
      user_location: null,
    }
  },
  methods: {
    async get_data() {
      this.categories = []
      await this.$http.get(process.env.VUE_APP_BASE_API + '/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user_id = response.data.user.id;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          if (this.user.locations && this.user.locations.length > 0) {
            this.user_location = this.user.locations[0].id;
          }
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/categories', { params: { all: true }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          if (this.user_location) {
            const categories = response.data.categories;
            for (let i = 0; i < categories.length; i += 1) {
              if (categories[i].location_id == this.user_location) {
                this.categories.push(categories[i])
              }
            }
          } else {
            this.categories = response.data.categories;
          }

          this.categories.forEach(cat => {
            cat.name_i18n.forEach(n => {
              cat['name_i18n_' + n.lang] = n.val;
            })
            cat.description_i18n.forEach(n => {
              cat['description_i18n_' + n.lang] = n.val;
            })
          })
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/languages', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.languages = response.data.languages;
        })
      this.loading = false;
    },
    delete_category(category) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/categories/' + category.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
      }
    },
    edit_category(category) {
      this.selected_category = category;
      this.modal_edit = true;
    },
    create_category() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/categories', this.category, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add = false;
          this.category = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    update_category() {
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/categories/' + this.selected_category.id, this.selected_category, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_edit = false;
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http.get(process.env.VUE_APP_BASE_API + '/admin/categories', {
          params: {
            search_phrase: this.search_phrase
          },
          headers: { Authorization: this.$store.getters.get_token }
        })
          .then(response => {
            this.categories = response.data.categories;
            this.search_loading = false;
            this.searching = false;
          })
      }
    },
  },
  mounted() {
    this.get_data();
  }
}
</script>
